<template>
  <b-container>
    <b-row>
      <b-col>
        <b-card align="left" class="register-form">
          <form>
            <b-form-group
              id="email-group"
              label="Email Address"
              label-for="email" >
              <b-form-input id="email"></b-form-input>
            </b-form-group>
            <b-form-group
              id="password"
              label="Password"
              label-for="password" >
              <b-form-input id="password" type="password"></b-form-input>
            </b-form-group>
            <b-button variant="primary" block>Register</b-button>
            <hr>
            <div align="center">
              Already a member? Sign In
            </div>
          </form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

  export default {

  }
</script>

<style scoped>
  .register-form {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
  }
</style>
