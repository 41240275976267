<template>
  <b-container>
    <b-row>
      <b-col md="12">
        <b-row class="pb-2">
          <b-col>
            <b-card bg-variant="warning">
              Development Area: <br>
              ID: {{ $route.params.id }}
           </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card align="left" no-body>
              <b-row no-gutters>
                <b-col md="3" v-if="posting">
                   <b-card-img :src="posting.company.imgUrl" alt="image" class="rounded-0"></b-card-img>
                </b-col>
                <b-col md="9">
                  <b-card-body>
                    <b-card-title v-if="posting">
                      {{ posting.title }}
                    </b-card-title>
                    <b-card-sub-title v-if="posting">
                      {{ posting.company.name }} <b-icon icon="geo-alt-fill"></b-icon> {{ posting.location.city }}, {{ posting.location.province }}, {{ posting.location.country }}
                    </b-card-sub-title>
                    <b-card-text v-if="posting">
                      <small>Posted 1 day ago - 12 views</small>
                    </b-card-text>
                    <b-card-text>
                      <b-row>
                        <b-col v-if="posting">
                          No of Positions: {{ posting.noOfPositions }}
                        </b-col>
                        <b-col v-if="posting">
                          Experience Level: {{ posting.experienceLevel.name }}
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col v-if="posting">
                          Employment Type: {{ posting.type.name }}
                        </b-col>
                        <b-col v-if="posting">
                          Pay Range: {{ posting.pay }}
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col v-if="posting">
                          Industry: {{ posting.industry.name }}
                        </b-col>
                      </b-row>
                    </b-card-text>
                    <b-card-text v-if="posting">
                      <b-badge pill v-for='tag in posting.tags' :key='tag.name' class="mr-1">{{ tag.name }}</b-badge>&nbsp;
                      <!-- <b-badge pill>Shift: 10 on 4 off</b-badge>&nbsp;
                      <b-badge pill>Fly In/Out</b-badge>&nbsp;
                      <b-badge pill>Paid Vacation</b-badge> -->
                    </b-card-text>
                  </b-card-body>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="pt-2">
      <b-col>
        <b-card align="left" no-body>
          <b-card-header>
            <b-button variant="primary">Apply</b-button>
          </b-card-header>
          <b-card-body v-if="posting">
            {{ posting.description }}
            <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque id vestibulum sem. Cras placerat augue sit amet dolor ultricies, quis bibendum metus consectetur. Etiam tincidunt libero sit amet quam tempus dapibus. Donec ultricies tristique facilisis. Nulla volutpat lacus vel diam sollicitudin tempor. Curabitur at malesuada nunc. Donec a dolor ut nulla hendrerit lacinia in a nisl. Vestibulum nec rutrum ipsum, id ornare diam. Mauris in dui massa. Sed ultrices luctus mattis. Pellentesque eu venenatis metus. Nam tempor libero a sollicitudin bibendum. Duis diam nisi, ultrices in mollis mollis, pellentesque vitae est. Praesent dignissim congue sem nec pellentesque. Etiam sed massa vulputate, convallis neque ut, tincidunt magna.
            <br><br>
            Nunc hendrerit sapien sed lectus hendrerit, ac egestas elit finibus. Nam volutpat ultrices blandit. Vivamus nec consectetur arcu. Vivamus ac ipsum sit amet nunc efficitur porta ut sit amet erat. Pellentesque eleifend ex sollicitudin nibh porta aliquam. Nam at tincidunt elit, at euismod leo. Vestibulum eget nisl vitae quam bibendum ultricies. Aenean tempus luctus augue, eu pharetra erat faucibus vitae. Praesent mi leo, blandit vel dui quis, feugiat auctor nisl. Sed vehicula nec libero ac scelerisque. In eros leo, pharetra sed vehicula non, hendrerit non nisl. In ligula ipsum, dapibus eu gravida sed, suscipit sit amet turpis. Nam nunc lacus, laoreet non rhoncus vitae, viverra in risus. Cras tempor feugiat orci et porttitor. Maecenas tincidunt dolor sed ullamcorper vulputate. Morbi iaculis ullamcorper ex, id commodo ipsum rutrum quis.
            <br><br>
            Sed velit erat, pretium at varius ac, ultrices sed justo. Aliquam auctor, sem ut hendrerit tristique, purus ante elementum erat, ut ullamcorper sapien turpis et mi. Aenean condimentum orci ac velit ullamcorper rhoncus. Nullam quis accumsan dolor. Nunc vel turpis a ante faucibus ultricies vitae id dolor. Nullam mattis neque sed nibh tincidunt viverra. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Cras ultrices vestibulum felis, vitae dictum nisi ultrices ac. Nullam eu lacus consectetur, convallis neque vel, faucibus sapien. Integer non tempor purus. Integer auctor fermentum tortor nec ullamcorper.
            <br><br>
            Nam mattis, nibh id convallis vulputate, sem nisl porta arcu, eget lobortis lacus nibh scelerisque dui. Suspendisse tempus finibus felis ac sodales. Morbi nibh nibh, bibendum at orci non, varius elementum tortor. Praesent sit amet velit mauris. Cras sit amet orci a ante semper finibus. Pellentesque pharetra volutpat orci et fringilla. Donec hendrerit justo nec quam tempor, quis fringilla lectus lobortis. Sed eget elit ac quam fringilla tristique. Aenean eget libero rutrum, ornare justo sed, sodales tellus. Maecenas eget quam quis urna vulputate ultrices. Curabitur imperdiet viverra purus, sit amet commodo lorem sodales aliquam. Nulla consectetur odio et lacus varius, a condimentum ligula maximus. Aliquam elementum iaculis nibh non volutpat. Morbi auctor ut est vel dignissim. Suspendisse vitae placerat ex. Praesent bibendum mauris et mollis ultricies. -->
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import JobService from '../services/jobs.service';

  export default {
    name: 'Posting',
    data () {
      return {
        posting: null
      }
    },
    mounted() {
      this.refreshModel()
    },
    methods: {
      async refreshModel() {
        // this.posting = await JobService.getPosting(this.$route.params.id);
        this.posting = await JobService.getPosting(this.$route.params.id);
      }
    }
  }
</script>

<style scoped>

</style>

