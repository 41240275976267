<template>
  <b-container>
    <b-row>
      <b-col md="12">
        <b-card align="left">
          <b-row>
            <b-col>
              <b-form-group
                id="search-group"
                label="Search"
                label-for="searchText">
                <b-form-input id="searchText"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="pt-2">
      <b-col md="3">
        <b-card align="left" no-body>
          <b-card-img src="https://picsum.photos/200/100" alt="image" class="rounded-0"></b-card-img>
          <b-card-body>
            <b-card-title>
              Company 1
            </b-card-title>
            <b-card-text>
              Total Jobs: 4
            </b-card-text>
            <b-card-text>
              Looking For:<br>
              <b-badge pill variant="secondary">Welders</b-badge>&nbsp;
              <b-badge pill variant="warning">Labourers</b-badge>&nbsp;
              <b-badge pill variant="info">Admin</b-badge>
            </b-card-text>
            <b-button variant="primary">View Company 1</b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card align="left" no-body>
          <b-card-img src="https://picsum.photos/200/100" alt="image" class="rounded-0"></b-card-img>
          <b-card-body>
            <b-card-title>
              Company 2
            </b-card-title>
            <b-card-text>
              Total Jobs: 1
            </b-card-text>
            <b-card-text>
              Looking For:<br>
              <b-badge pill variant="secondary">Electricians</b-badge>&nbsp;
              <b-badge pill variant="info">Office Admin</b-badge>
            </b-card-text>
            <b-button variant="primary">View Company 2</b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card align="left" no-body>
          <b-card-img src="https://picsum.photos/200/100" alt="image" class="rounded-0"></b-card-img>
          <b-card-body>
            <b-card-title>
              Company 3
            </b-card-title>
            <b-card-text>
              Total Jobs: 8
            </b-card-text>
            <b-card-text>
              Looking For:<br>
              <b-badge pill variant="secondary">Welders</b-badge>&nbsp;
            </b-card-text>
            <b-button variant="primary">View Company 3</b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card align="left" no-body>
          <b-card-img src="https://picsum.photos/200/100" alt="image" class="rounded-0"></b-card-img>
          <b-card-body>
            <b-card-title>
              Company 4
            </b-card-title>
            <b-card-text>
              Total Jobs: 2
            </b-card-text>
            <b-card-text>
              Looking For:<br>
              <b-badge pill variant="secondary">Pipe Fitters</b-badge>&nbsp;
              <b-badge pill variant="warning">Labourers</b-badge>&nbsp;
              <b-badge pill variant="success">Drivers</b-badge>
              <b-badge pill variant="light">Heavy Duty Mechanic</b-badge>
              <b-badge pill variant="dark">Carpenters</b-badge>
            </b-card-text>
            <b-button variant="primary">View Company 4</b-button>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

  export default {

  }
</script>

<style scoped>

</style>
