let postings = [
    { id: 1,
      title: 'High Voltage Electrician',
      company: {
        id: 1,
        name: 'Electrical Company 1',
        imgUrl: 'https://picsum.photos/id/1029/200'
      },
      location: {
        city: 'Vancouver',
        province: 'BC',
        country: 'Canada'
      },
      noOfPositions: 3,
      experienceLevel: {
        id: 1,
        name: '4th Year'
      },
      type: {
        id: 1,
        name: 'Full Time'
      },
      pay: '$35-60/hour',
      industry: {
        id: 1,
        name: 'Oil & Gas'
      },
      tags: [
        { id: 1, name: 'Dry Camp' },
        { id: 2, name: 'Shift: 10 on 4 off' },
        { id: 3, name: 'Fly In/Out' },
        { id: 4, name: 'Paid Vacation' }
      ],
      description: 'Job Description will go here.',
      views: 1,
      created_at: '',
      lastupdated_at: ''
    },
    { id: 2,
      title: 'Super Duper Welder',
      company: {
        id: 1,
        name: 'Welding Company 1',
        imgUrl: 'https://picsum.photos/200'
      },
      location: {
        city: 'Nelson',
        province: 'BC',
        country: 'Canada'
      },
      noOfPositions: 5,
      experienceLevel: {
        id: 1,
        name: '1st Year'
      },
      type: {
        id: 1,
        name: 'Part Time'
      },
      pay: '$20-25/hour',
      industry: {
        id: 1,
        name: 'Commercial'
      },
      tags: [
        { id: 5, name: 'Local Work' }
      ],
      description: 'Job Description will go here.',
      views: 1,
      created_at: '',
      lastupdated_at: ''
    },
    { id: 3,
      title: 'General Labour',
      company: {
        id: 1,
        name: 'Some Weird Company',
        imgUrl: 'https://picsum.photos/200'
      },
      location: {
        city: 'Edmonton',
        province: 'AB',
        country: 'Canada'
      },
      noOfPositions: 15,
      experienceLevel: {
        id: 1,
        name: 'No Experience'
      },
      type: {
        id: 1,
        name: 'Full Time'
      },
      pay: '$15-20/hour',
      industry: {
        id: 1,
        name: 'Residential'
      },
      tags: [
        { id: 5, name: 'Local Work' },
        { id: 5, name: 'Benefits' },
        { id: 5, name: 'Paid Vacation' }
      ],
      description: 'Job Description will go here.',
      views: 1,
      created_at: '',
      lastupdated_at: ''
    }
  ]

export default {
  async getPosting(id) {
    return postings[id-1];
    // return postings[id-1];
  }
}
