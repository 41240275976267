import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import App from './App.vue'
import VueRouter from 'vue-router'

import Home from "@/components/Home"
import Login from "@/components/Login"
import Jobs from "@/components/Jobs"
import Companies from "@/components/Companies"
import Register from "@/components/Register"
import Requests from "@/components/Requests"
import Profile from "@/components/Profile"
import Posting from "@/components/Posting"

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueRouter)

const routes = [
  {
    path: "",
    component: Home,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/jobs",
    component: Jobs,
    children: [
      { path: '' },
      { path: ':id', component: Posting }
    ]
  },
  {
    path: "/companies",
    component: Companies,
  },
  {
    path: "/requests",
    component: Requests,
  },
  {
    path: "/profile",
    component: Profile,
  },
  {
    path: "*",
    component: { render: (h) => h("div", ["404! Page Not Found!"]) },
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
