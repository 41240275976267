<template>
  <b-container>
    <b-row>
      <b-col>
        <b-row>
          <b-col md="12">
            <b-card align="left">
              <b-row>
                <b-col>
                  <b-form-group
                    id="search-group"
                    label="Search"
                    label-for="searchText">
                    <b-form-input id="searchText"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <b-form-group label="Date Posted">
                    <b-form-select></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Experience Level">
                    <b-form-select></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Job Type">
                    <b-form-select></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="pt-2">
          <b-col md="4" class="pb-2">
            <b-card align="left">
              <b-list-group flush>
                <b-list-group-item>
                  <b-link :to="'/jobs/1'">High Voltage Electrican</b-link>
                </b-list-group-item>
                <b-list-group-item>
                  <b-link :to="'/jobs/2'">Super Duper Welder</b-link>
                </b-list-group-item>
                <b-list-group-item>
                  <b-link :to="'/jobs/3'">General Labour</b-link>
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-col>
          <b-col>
            <b-card align="left">
              <router-view :key="$route.fullPath" />
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

  export default {

  }
</script>

<style scoped>

</style>
