<template>
  <b-container>
    <b-row>
      <b-col>
        <p>Here you will find all the asked of you.</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <!-- <ul> -->
          <li>Interview Ruquests</li>
          <li>Resume Review Ruquests</li>
          <li>Interview Prep Requests</li>
          <li>Feedback Review Requests</li>
        <!-- </ul> -->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

  export default {

  }
</script>

<style scoped>

</style>
