<template>
  <b-container>
    <b-row>
      <b-col>
        <b-card header="Search">
          Some search options in here
        </b-card>
      </b-col>
      <b-col cols="6">
        <h3>Welcome to <b><i>site name here</i></b></h3>
        <p>We will need to put shit here.</p>
      </b-col>
      <b-col>
        <b-card header="profile">
          Profile goes here.
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

  export default {

  }
</script>

<style scoped>

</style>
