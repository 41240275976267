<template>
  <b-container>
    <b-row>
      <b-col md="9">
        <b-row>
          <b-col>
            <b-card align="left" no-body>
              <b-row no-gutters>
                <b-col md="3">
                  <b-card-img src="https://picsum.photos/id/31/200" alt="image" class="rounded-0"></b-card-img>
                </b-col>
                <b-col md="9">
                  <b-card-body>
                    <b-card-title>
                      Al Bundy<br>
                    </b-card-title>
                    <b-card-sub-title>
                      Shoe Salesman @ Gary's Shoes and Accessories
                    </b-card-sub-title>
                    <b-card-text>
                      <p>Chicago, Illinois</p>
                      <p>Available to work</p>
                    </b-card-text>
                  </b-card-body>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="pt-2">
          <b-col class="pr-1">
            <b-card header="Ideal Work Environment">
              <b-badge>Shift: 10-4</b-badge>&nbsp;
              <b-badge>No Nights</b-badge>&nbsp;
              <b-badge>Dry Camp</b-badge>&nbsp;
              <b-badge>Fly In/Out</b-badge>&nbsp;
            </b-card>
          </b-col>
          <b-col class="pl-1">
            <b-card header="Skills">
              <b-badge>Residential</b-badge>&nbsp;
              <b-badge>Commercial</b-badge>&nbsp;
              <b-badge>Industrial</b-badge>&nbsp;
              <b-badge>High Voltage</b-badge>&nbsp;
            </b-card>
          </b-col>
        </b-row>
        <b-row class="pt-2">
          <b-col>
            <b-card header="Work Experience" align="left">
              <b-list-group flush>
                <b-list-group-item class="d-flex justify-content-between align-items-center">
                  From - To - Company - Attribute - Job Title
                  <b-badge variant="light" pill>Calc of duration</b-badge>
                </b-list-group-item>
                <b-list-group-item class="d-flex justify-content-between align-items-center">
                  Dec 2019 - Present - Alcan Electric - Journeyman - Supervisor
                  <b-badge variant="light" pill>1 Year</b-badge>
                </b-list-group-item>
                <b-list-group-item class="d-flex justify-content-between align-items-center">
                  Nov 2018 - Dec 2019 - Alcan Electric - 4th Year - Electrician
                  <b-badge variant="light" pill>1 Year, 1 Month</b-badge>
                </b-list-group-item>
                <b-list-group-item class="d-flex justify-content-between align-items-center">
                  Oct 2016 - Nov 2018 - BC Hydro - 3rd Year - Electrician
                  <b-badge variant="light" pill>2 Years, 2 Months</b-badge>
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="pt-2">
          <b-col>
            <b-card header="Certifications" align="left">
              <b-list-group flush>
                <b-list-group-item class="d-flex justify-content-between align-items-center">
                  <span>April 2020 - Commercial First Aid&nbsp;<b-icon icon="check2-circle" variant="success"></b-icon></span>
                  <b-badge variant="success" pill>Current</b-badge>
                </b-list-group-item>
                <b-list-group-item class="d-flex justify-content-between align-items-center">
                  March 2018 - Certified Electrical Inspector
                  <b-badge variant="success" pill>Current</b-badge>
                </b-list-group-item>
                <b-list-group-item class="d-flex justify-content-between align-items-center">
                  January 2015 - WHIMIS
                  <b-badge variant="warning" pill>Expired</b-badge>
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="pt-2" align="left">
          <b-col>
            <b-card header="Education">
            </b-card>
          </b-col>
        </b-row>
        <b-row class="pt-2 pb-2">
          <b-col>
            <b-card header="References and Recommendations" align="left">
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <b-row>
          <b-col>
            <b-card header="Skill Assessements" bg-variant="primary" text-variant="white">
              <b-card-text color="primary">
                <p><small>have some assessements that we generate or companies generate for people to complete. (Optional or not for jobs they are applying to</small></p>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="pt-2">
          <b-col>
            <b-card>
              <b-card-text>
                <p><b>Are you interested in working with an advisor?</b></p>
                <b-button variant="primary" block>Connect</b-button>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="pt-2">
          <b-col>
            <b-card>
              Open Jobs: 123<br>
              24 Electrician Jobs<br>
              Avg. time to hire: 2 weeks
            </b-card>
          </b-col>
        </b-row>
        <b-row class="pt-2">
          <b-col>
            <b-card>
              <b-card-text>
                <p><b>Interesting Jobs</b></p>
                <b-list-group flush>
                  <b-list-group-item>
                    Residential Electrician
                  </b-list-group-item>
                  <b-list-group-item>
                    High Voltage Elec...
                  </b-list-group-item>
                  <b-list-group-item>
                    Commercial Elec...
                  </b-list-group-item>
                </b-list-group>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

  export default {

  }
</script>

<style scoped>

</style>

